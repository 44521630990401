import * as React from 'react'
import TokenGenerator from "../../services/auth";
import axios, {AxiosResponse} from 'axios'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {LiveTrainInterface} from "../../domain/LiveTrainInterface";

export interface LiveTrainProps {
    liveTrain: LiveTrainInterface
}

interface LiveTrainState {
    liveTrainId: string,
    stationFrom: string,
    scheduledDepartureTime: string,
    expectedDepartureTime: string,
    stationTo: string,
    scheduledArrivalTime: string,
    expectedArrivalTime: string,
    delayInMinutes: number,
    status: string,
    tripDurationMinutes: number,
    finalDestination: string,
    desktopUrlToNationalRail: string,
    mobileUrlToNationalRail: string,
    expectedDepartureTimeBeforeNow: boolean,
    departureTrainAtStation: string,
}

class LiveTrain extends React.Component<LiveTrainProps, LiveTrainState> {
    empty: LiveTrainState = {
        liveTrainId: "",
        stationFrom: "-",
        scheduledDepartureTime: "-",
        expectedDepartureTime: "-",
        stationTo: "-",
        scheduledArrivalTime: "-",
        expectedArrivalTime: "-",
        delayInMinutes: 0,
        status: "-",
        tripDurationMinutes: 0,
        finalDestination: "-",
        desktopUrlToNationalRail: "-",
        mobileUrlToNationalRail: "-",
        expectedDepartureTimeBeforeNow: false,
        departureTrainAtStation: "-",
    };

    constructor(props: LiveTrainProps) {
        super(props)
        this.state = this.empty
    }

    padding = {padding: "5px 4px 4px"}
    fontStyle = {fontSize: "11px", fontFamily: "verdana", ...this.padding}
    red = {color: "red", ...this.fontStyle}
    black = {color: "black", ...this.fontStyle}
    greyedOut = {color: "grey", ...this.fontStyle}
    strikethrough_red = {"text-decoration": "line-through", ...this.fontStyle, ...this.red}
    noPadding = {padding: "0px 0px 0px 0px", marginBottom: "auto", marginTop: "auto"}
    finalDestinationStyle = {...this.fontStyle, width: "150px"}

    render() {
        let styleScheduledDeparture
        let styleScheduledArrival
        let scheduleDepartureTag
        let cancelled = this.props.liveTrain.status === 'CANCELLED';
        let departureDelay = <></>

        if (cancelled) {
            styleScheduledDeparture = this.strikethrough_red
            scheduleDepartureTag = <>{this.state.scheduledDepartureTime}</>
        } else {
            styleScheduledDeparture = this.black

            let delayedDeparture = this.state.expectedDepartureTime !== this.state.scheduledDepartureTime
            if (delayedDeparture) {
                styleScheduledDeparture = {
                    ...styleScheduledDeparture,
                    ...this.noPadding
                }
                scheduleDepartureTag = (
                    <>
                        <span style={this.greyedOut}>{this.state.scheduledDepartureTime}</span>
                        <p style={this.noPadding}>
                            <a href={this.state.mobileUrlToNationalRail} style={this.red}>
                                {this.state.expectedDepartureTime}
                            </a>
                        </p>
                    </>
                )
            } else {
                scheduleDepartureTag =
                    <a href={this.state.mobileUrlToNationalRail}>{this.state.scheduledDepartureTime}</a>
            }
        }

        let delayedArrival = this.state.expectedArrivalTime !== this.state.scheduledArrivalTime
        styleScheduledArrival = delayedArrival ? this.red : this.black
        if (delayedArrival) {
            console.log(this.state)
        }

        let platform = this.props.liveTrain.platformAtRouteStart
        if (!platform) {
            platform = "-"
        }

        let tripDurationMinutes = this.state.tripDurationMinutes <= 0 ? "-" : this.state.tripDurationMinutes + "m"

        return (
            <TableBody>
                <TableRow key={this.state.liveTrainId} style={{backgroundColor: "#ffffff"}}>
                    <TableCell align="left" component="th" scope="row" style={styleScheduledDeparture}>
                        {scheduleDepartureTag}{departureDelay}
                    </TableCell>
                    <TableCell style={this.finalDestinationStyle} align="left">{this.state.finalDestination}</TableCell>
                    <TableCell style={this.fontStyle} align="right">{platform}</TableCell>
                    <TableCell style={styleScheduledArrival} align="right">{this.state.expectedArrivalTime}</TableCell>
                    <TableCell style={this.fontStyle} align="right">{tripDurationMinutes}</TableCell>
                </TableRow>
            </TableBody>
        )
    }

    shouldComponentUpdate(nextProps: LiveTrainProps, nextState: LiveTrainState): boolean {
        return true
    }

    componentDidUpdate(prevProps: LiveTrainProps, prevState: LiveTrainState) {
    }

    async componentDidMount() {
        const baseUrl = "https://api.easy-commuting.carlosrodriguez.tech"
        const path = `/live-trains/${encodeURIComponent(this.props.liveTrain.liveTrainId)}/trip-durations`;
        const queryString = `?stationFrom=${this.props.liveTrain.stationFrom}&stationTo=${this.props.liveTrain.stationTo}`;

        TokenGenerator.instance.getIdToken()
            .then((idToken: string) => {
                let config = {
                    headers: {
                        Authorization: "Bearer " + idToken
                    }
                }
                axios.get<LiveTrainState>(baseUrl + path + queryString, config)
                    .then((response: AxiosResponse<LiveTrainState>) => {
                        // let nextState = this.enrichState(response.data);
                        this.setState(response.data)
                    })
                    .catch((error: any) => {
                        console.log(error)
                        this.setState({
                            liveTrainId: this.props.liveTrain.liveTrainId,
                            stationFrom: this.props.liveTrain.stationFrom,
                            scheduledDepartureTime: this.props.liveTrain.scheduledDepartureTime,
                            expectedDepartureTime: this.props.liveTrain.expectedDepartureTime,
                            stationTo: this.props.liveTrain.stationTo,
                            scheduledArrivalTime: "-",
                            expectedArrivalTime: "-",
                            delayInMinutes: this.props.liveTrain.delayInMinutes,
                            status: this.props.liveTrain.status,
                            tripDurationMinutes: -1,
                            finalDestination: this.props.liveTrain.finalDestination,
                            desktopUrlToNationalRail: this.props.liveTrain.desktopUrlToNationalRail,
                            mobileUrlToNationalRail: this.props.liveTrain.mobileUrlToNationalRail
                        })
                    });

            })
    }
}

export default LiveTrain
