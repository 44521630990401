import * as React from 'react'
import {RouteInterface} from "../../domain/RouteInterface";
import TokenGenerator from "../../services/auth";
import axios, {AxiosResponse} from 'axios'
import {LiveTrainInterface} from "../../domain/LiveTrainInterface";
import LiveTrain from "./LiveTrain";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

interface RouteProps {
    route: RouteInterface
}

interface RouteState {
    liveTrains: LiveTrainInterface[]
}

class Route extends React.Component<RouteProps, RouteState> {
    empty: RouteState = {
        liveTrains: []
    }

    fontStyle = {fontSize: "10px", fontFamily: "verdana", backgroundColor: "#ffffff"}
    cellStyle = {...this.fontStyle}
    containerStyle = {
        paddingBottom: "12px",
        minWidth: "355px",
        maxWidth: "355px",
        "text-align": "justify"
    }

    constructor(props: RouteProps) {
        super(props)
        this.state = this.empty
    }

    async componentDidMount() {
        this.fetchLiveTrains();
    }

    private fetchLiveTrains() {
        const url = "https://api.easy-commuting.carlosrodriguez.tech/live-trains"
        let from = this.props.route.stationFrom;
        let to = this.props.route.stationTo;
        let limit = this.props.route.limit;
        console.log("Reloading route " + from + " to " + to)
        const queryParams = `?stationFrom=${from}&stationTo=${to}&limit=${limit}`

        TokenGenerator.instance.getIdToken()
            .then((idToken: string) => {
                let config = {
                    headers: {
                        Authorization: "Bearer " + idToken
                    }
                }
                axios.get<LiveTrainInterface[]>(url + queryParams, config)
                    .then((response: AxiosResponse<LiveTrainInterface[]>) => {
                        this.setState({liveTrains: response.data})
                    })
                    .catch((error: any) => {
                        console.log(error)
                        this.setState(this.empty)
                    });

            })
    }

    refresh() {
        this.setState(this.empty)
        this.fetchLiveTrains()
    }

    render() {
        let liveTrainsComponents = this.state.liveTrains.map(liveTrain =>
            <LiveTrain key={liveTrain.liveTrainId} liveTrain={liveTrain}/>
        );

        return (
            <>
                <div style={this.containerStyle}>
                    <table style={{"width": "100%", "border": 0}}>
                        <tr>
                            <td align={"left"}>
                                <a style={{...this.fontStyle, fontWeight: "bold", paddingLeft: "5px"}}
                                   href={this.props.route.urlToNationalRail}>
                                    {this.props.route.stationFrom}
                                    {'\u00A0'}{'\u00A0'}
                                    {'\u2192'}
                                    {'\u00A0'}{'\u00A0'}
                                    {this.props.route.stationTo}
                                </a>
                            </td>
                            <td align={"right"}>
                                <a href="/#" onClick={() => this.refresh()} style={{paddingRight: "5px"}}>
                                    <img style={{height: "13px", width: "13px"}} alt={"refresh"}
                                         src='./refresh-icon.png'/>
                                </a>
                            </td>
                        </tr>
                    </table>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={this.cellStyle} align="left"> </TableCell>
                                    <TableCell style={this.cellStyle} align="left"> </TableCell>
                                    <TableCell style={this.cellStyle} align="right"> </TableCell>
                                    <TableCell style={this.cellStyle} align="right"> </TableCell>
                                    <TableCell style={this.cellStyle} align="right"> </TableCell>
                                </TableRow>
                            </TableHead>
                            {liveTrainsComponents}
                        </Table>
                    </TableContainer>
                </div>
            </>
        )
    }
}

export default Route