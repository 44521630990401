import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

let style = {
    backgroundColor: "#ffffff",
    width: "100%",
    top: 0,
    left: 0,
    color: "white",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

ReactDOM.render(
    <div style={style}>
        <App/>
    </div>,

    // <React.StrictMode>
    //     <App/>
    // </React.StrictMode>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
