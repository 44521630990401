import * as React from 'react'
import {RouteGroupInterface} from "../../domain/RouteGroupInterface";
import Route from "./Route";

interface RouteGroupProps {
    routeGroup: RouteGroupInterface
}

interface RouteGroupState {
    routeGroup: RouteGroup
}

class RouteGroup extends React.Component<RouteGroupProps, RouteGroupState> {
    containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    render() {
        const routes = []
        for (let route of this.props.routeGroup.routes) {
            routes.push(
                <div style={this.containerStyle}>
                    <Route route={route}/>
                </div>
            )
        }
        return routes
    }
}

export default RouteGroup