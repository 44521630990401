import Amplify, {Auth} from 'aws-amplify';
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";

Amplify.configure({
    aws_app_analytics: 'disable',
    aws_user_pools: 'enable',
    aws_user_pools_id: 'eu-west-1_LoXZ5ZA8c',
    aws_user_pools_mfa_type: 'ON',
    aws_user_pools_web_client_id: '1i00dmrtav77ufr3ko7lk0i8hn',
    aws_user_settings: 'enable',
});

interface Credentials {
    username: string,
    password: string
}

export default class TokenGenerator {
    private static readonly DEFAULT_CREDENTIALS: Credentials = {
        username: "carloshenrique153@gmail.com",
        password: "Password#1",
    }
    private static _instance: TokenGenerator;

    public static get instance(): TokenGenerator {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
    }

    public async getIdToken(): Promise<string> {
        return Auth.currentSession()
            .then((session: CognitoUserSession) => {
                return session.isValid() ?
                    session.getIdToken().getJwtToken() :
                    TokenGenerator.generateIdToken()
            })
            .catch((message: string) => {
                console.log(message)
                return TokenGenerator.generateIdToken()
            })
    }

    private static async generateIdToken(): Promise<string> {
        console.log("Generating token")
        let response: CognitoUser = await Auth.signIn(
            TokenGenerator.DEFAULT_CREDENTIALS.username,
            TokenGenerator.DEFAULT_CREDENTIALS.password
        )
        return response.getSignInUserSession()!.getIdToken().getJwtToken()
    }
}
