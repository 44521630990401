import React from 'react';
import './App.css';
import Radium from 'radium'
import RouteGroups from "../components/Routes/RouteGroups";

// let routes: RouteProps[] = [
//     {id: "1", from: "Station A", to: "Station B"},
//     {id: "2", from: "Station A", to: "Station C"}
// ]

function App() {
    // const buttonStyle = {
    //     backgroundColor: 'white',
    //     ':hover': {
    //         fontWeight: 'bold'
    //     }
    // };
    return (
        <div className="App">
            {/*<LifecycleExample a={"hello"} b={"world"}/>*/}
            {/*<Authentication/>*/}
            {/*<AuthenticationEseye/>*/}
            {/*<button style={buttonStyle}>Hello Radium</button>*/}
            {/*<Routes routes={routes}/>*/}
            {/*<Route id={"xFsEJLialBjojPEj5b2X4g=="} from={"London St Pancras International"} to={"East Croydon"} platformAtRouteStart={"4"}/>*/}
            <RouteGroups/>
        </div>
    );
}

export default Radium(App);
