import * as React from 'react'
import axios, {AxiosResponse} from 'axios'
import TokenGenerator from "../../services/auth";
import {RouteGroupInterface} from "../../domain/RouteGroupInterface";
import RouteGroup from "./RouteGroup";
import {ExpansionPanel} from "@material-ui/core";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

interface RouteGroupsProps {
}

interface RouteGroupsState {
    routeGroups: RouteGroupInterface[],
    visible: Set<string>
}

class RouteGroups extends React.Component<RouteGroupsProps, RouteGroupsState> {
    private readonly visibleRouteGroups: Set<string>;

    constructor(props: RouteGroupsProps) {
        super(props)
        this.visibleRouteGroups = new Set<string>();
        this.state = {
            routeGroups: [],
            visible: this.visibleRouteGroups
        }
    }

    componentDidMount() {
        const url = "https://api.easy-commuting.carlosrodriguez.tech/route-groups"
        TokenGenerator.instance.getIdToken()
            .then((idToken: string) => {
                let config = {
                    headers: {
                        Authorization: "Bearer " + idToken
                    }
                }
                axios.get<Array<RouteGroupInterface>>(url, config)
                    .then((response: AxiosResponse<Array<RouteGroupInterface>>) => {
                        this.setState({
                            routeGroups: response.data,
                            visible: this.state.visible
                        })
                    })
            })
    }

    fontStyle = {
        fontSize: "14px",
        fontFamily: "verdana"
    }

    panelStyle = {
        padding: "0px 0px 0px",
        display: 'flex',
        minWidth: "350px",
        justifyContent: 'center',
        alignItems: 'center'
    };

    render() {
        const rendered: any[] = []
        this.state.routeGroups.forEach(rg => {
            let element = null
            if (this.visibleRouteGroups.has(rg.routeGroupId)) {
                element = <RouteGroup key={rg.routeGroupId} routeGroup={rg}/>
            }
            rendered.push(
                <div key={rg.routeGroupId} style={{textAlign: "justify", paddingBottom: "10px"}}>
                    <ExpansionPanel style={
                        {backgroundColor: "#ffffff"}
                    }>
                        <ExpansionPanelSummary
                            onClick={() => this.onClick(rg.routeGroupId)}
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography style={this.fontStyle}>{rg.routeGroupId}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={this.panelStyle}>
                            <Typography>
                                {element}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            );
        })
        return (
            <div>{rendered}</div>
        )
    }

    private onClick(routeGroupId: string) {
        if (this.visibleRouteGroups.has(routeGroupId)) {
            this.visibleRouteGroups.delete(routeGroupId)
        } else {
            this.visibleRouteGroups.add(routeGroupId)
        }
        this.setState({
            ...this.state
        })
    }
}

export default RouteGroups